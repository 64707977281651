*, :before, :after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
  font-weight: 400;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.widget-section, .widget-section .widget {
  width: 100%;
  height: 100%;
}

.widget-instructions__content {
  width: 500px !important;
  padding: 60px 30px 40px !important;
}

.widget-instructions__step p {
  max-width: 200px !important;
}

.widget-tab-button--active img {
  filter: none !important;
}
/*# sourceMappingURL=index.b2f6f983.css.map */
